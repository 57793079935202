<template>
  <HelpComponent>
    <b-row class="mb-3">
      <b-col>
        Vos apports apparaissent dans cet &eacute;cran le lendemain de leur
        saisie par votre coop&eacute;rative.
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        Leurs caract&eacute;ristiques restent &agrave; 0 jusqu'au lendemain de
        la saisie du r&eacute;sultat d'analyse par votre coop&eacute;rative.
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        Le bon d'apport est t&eacute;l&eacute;chargeable le lendemain de sa
        facturation par votre coop&eacute;rative.
      </b-col>
    </b-row>
    <b-row class="mb-3">
      <b-col>
        <em>
          Les apports sont, dans la mesure du possible, saisis le jour de
          l'apport. Les facturations se font une fois par 15aine.
        </em>
      </b-col>
    </b-row>
    <WarningBloc>
      Dans le cas d'un apport en m&eacute;lange d'esp&egrave;ces, la
      r&eacute;partition du poids entre les composants du m&eacute;lange n'est
      connue qu'<strong>apr&egrave;s analyse</strong>
      : ne tenez pas compte des poids indiqu&eacute;s dans le tableau pour ces
      apports tant que leurs caract&eacute;ristiques sont &agrave; 0.
    </WarningBloc>
  </HelpComponent>
</template>

<script>
import HelpComponent from "./HelpComponent.vue";
import WarningBloc from "../blocs/WarningBloc.vue";
export default {
  name: "HelpBonApportComponent",
  components: { HelpComponent, WarningBloc },
};
</script>
