<template>
  <section>
    <TitleBar>
      Bons d'apport
      <template #toolbar-content>
        <HelpBonApportComponent></HelpBonApportComponent>
        &nbsp;
        <TableButtonGroup
          :tableKey="tableKey"
          @rechercher="rechercher"
        ></TableButtonGroup>
      </template>
    </TitleBar>
    <b-container fluid>
      <ErrorComponent :error="error" @hide="error = null"></ErrorComponent>
      <StrapiEditoComponent
        :societe="exploitationCourante.codeSociete"
      ></StrapiEditoComponent>
      <PaginatedTable
        :tbodyTrClass="rowClass"
        :fields="fields"
        :busy="busy"
        :pageable="pageable"
        :page="page"
        @change="pageableChanged"
      >
        <template #mobile-card="{ item }">
          <BonApportCard :item="item"></BonApportCard>
        </template>
        <template #cell(dateApport)="{ item }">
          {{ isoSqlDateToFrenchDate(item.dateApport) }}
        </template>
        <template #cell(culture)="{ item }">
          {{ getLibelleProduit(item.codeProduit) }}
          {{ getLibelleVariete(item.codeVariete) }}
        </template>
        <template #cell(poidsLivre)="{ item }">
          <MontantComponent :value="item.poidsLivre"></MontantComponent>
        </template>
        <template #cell(poidsAuxNormes)="{ item }">
          <MontantComponent :value="item.poidsAuxNormes"></MontantComponent
        ></template>
        <template #cell(tauxHumidite)="{ item }">
          <MontantComponent :value="item.tauxHumidite"></MontantComponent
        ></template>
        <template #cell(tauxImpurete)="{ item }">
          <MontantComponent :value="item.tauxImpurete"></MontantComponent
        ></template>
        <template #cell(poidsSpecifique)="{ item }">
          <MontantComponent :value="item.poidsSpecifique"></MontantComponent
        ></template>
        <template #cell(fichier)="{ item }">
          <DownloadComponent
            v-if="item.pieceJointe"
            :typeDocument="typeDocument"
            :targetId="item.numeroApport"
          ></DownloadComponent>
          <template v-else>
            <b-icon-exclamation-triangle-fill
              :ref="`apport-${item.numeroApport}`"
              variant="warning"
            >
            </b-icon-exclamation-triangle-fill>
            <b-tooltip
              :target="() => $refs[`apport-${item.numeroApport}`]"
              triggers="hover"
              variant="warning"
            >
              Poids et caract&eacute;ristiques non confirm&eacute;s
              jusqu'&agrave; &eacute;dition du bon d'apport
            </b-tooltip>
          </template>
        </template>
        <template #pagination>
          <template v-if="currentCodeRecolte">
            Campagne {{ currentCodeRecolte }} :
            <strong>
              <MontantComponent
                :value="getTotalApporte(currentCodeRecolte)"
              ></MontantComponent>
            </strong>
            t apport&eacute;es
          </template>
          <template v-else>
            Toutes campagnes :
            <strong>
              <MontantComponent
                :value="totalApporteToutesCampagnes"
              ></MontantComponent>
            </strong>
            t apport&eacute;es
          </template>
        </template>
      </PaginatedTable>
    </b-container>
  </section>
</template>

<script>
import TitleBar from "../../components/TitleBar.vue";
import HelpBonApportComponent from "../../components/controls/HelpBonApportComponent.vue";
import TableButtonGroup from "../../components/controls/TableButtonGroup.vue";
import ErrorComponent from "../../components/ErrorComponent.vue";
import StrapiEditoComponent from "../../components/strapi/StrapiEditoComponent.vue";
import PaginatedTable from "../../components/PaginatedTable.vue";
import BonApportCard from "../../components/cards/BonApportCard.vue";
import MontantComponent from "../../components/MontantComponent.vue";
import DownloadComponent from "../../components/controls/DownloadComponent.vue";
import ExploitationService from "../../services/exploitation.service";
import { TableKeys } from "../../store/tables.defaults";
import {
  dataFactory as tableDataFactory,
  created,
  computed as tableComputed,
  methods as tableMethods,
} from "../../services/tables.service";
import UtilsService from "../../services/utils.service";
export default {
  name: "BonsApportView",
  components: {
    TitleBar,
    HelpBonApportComponent,
    TableButtonGroup,
    ErrorComponent,
    StrapiEditoComponent,
    PaginatedTable,
    BonApportCard,
    MontantComponent,
    DownloadComponent,
  },
  data() {
    return {
      ...tableDataFactory(TableKeys.BONS_APPORT),
      typeDocument: this.$store.state.expl.typesDocument.BON_APPORT,
      error: null,
      page: null,
      currentCodeRecolte: null,
      totauxApport: [],
    };
  },
  computed: {
    ...tableComputed,
    exploitationCourante() {
      return this.$store.getters["expl/adherentCourant"];
    },
    totalApporteToutesCampagnes() {
      return this.totauxApport.reduce(
        (acc, next) => acc + next.poidsLivre,
        0.0
      );
    },
  },
  created,
  mounted() {
    this.loadTotaux();
    this.load();
  },
  methods: {
    ...tableMethods,
    rowClass(item, type) {
      return type !== "row" || item?.pieceJointe ? undefined : "table-warning";
    },
    isoSqlDateToFrenchDate: UtilsService.isoSqlDateToFrenchDate,
    async loadTotaux() {
      let response = await ExploitationService.getTotalApportParCampagne(
        this.exploitationCourante.codeTiers
      );
      this.totauxApport = response.data;
    },
    getTotalApporte(codeRecolte) {
      return (
        this.totauxApport.find((t) => t.codeRecolte == codeRecolte)
          ?.poidsLivre ?? 0.0
      );
    },
    async load() {
      this.error = null;
      try {
        this.busy = true;
        let payload = this.getSearchPayload();
        let response = await ExploitationService.getBonsApport(
          this.exploitationCourante.codeTiers,
          payload
        );
        this.page = response.data;
        this.currentCodeRecolte = payload.genericFilters.operands.find(
          (f) => f.property === "recolte.codeRecolte"
        )?.operands[0];
      } catch (error) {
        this.page = null;
        this.currentCodeRecolte = null;
        this.error = UtilsService.handleError(error);
      } finally {
        this.busy = false;
      }
    },
    getLibelleProduit(codeProduit) {
      return (
        this.$store.getters["ref/produitCereale"](codeProduit)?.libelle ?? ""
      );
    },
    getLibelleVariete(codeVariete) {
      return this.$store.getters["ref/variete"](codeVariete)?.libelle ?? "";
    },
  },
};
</script>
